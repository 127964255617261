<template>
  <div class="main">
    <div class="head">
      <el-alert style="margin-bottom:10px;" title="注：1-数据来源于各省市的招生考试院；2-该数据仅为高校拟招生专业的选考科目要求，不是最终的专业计划安排；3-各位考生应以高考当年招生计划中的选考要求为准。"
    type="error" :closable="false"></el-alert>
      <div class="head-item">
        <div class="screen-item">
          <div class="title">招生省份：</div>
          {{ provinceObj.ProvinceName }}
          <!-- <el-select v-model="query.ProvinceId" @change="selectParameters('province')">
            <el-option
              v-for="(item, index) in newExamProvinceList"
              :key="index"
              :label="item.ProvinceName"
              :value="item.ID"
            />
          </el-select> -->
        </div>
        <div class="screen-item">
          <div class="title">年份：</div>
          <el-select v-model="query.Year" @change="selectParameters('year')">
            <el-option
              v-for="(item, index) in newExamYearList"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <span class="title-item emphasize-bold">查询结果：</span>
        <span class="title-item emphasize-color-red">{{mainData.Total}}条</span>
      </div>
      <div class="content-box">
        <el-table
          :data="mainData.Data"
          border
          empty-text="暂无数据..."
          :header-cell-style="{background: 'rgba(var(--themecolor),0.2)', color: '#555'}"
          style="width: 100%"
        >
          <el-table-column align="center" prop="SpecialtyName" label="专业名称" width="220"/>
          <el-table-column align="center" prop="ExamCondition" label="选考要求" width="280"/>
          <el-table-column align="center" prop="Specialties" label="包含专业"/>
          <el-table-column align="center" prop="ExamDirection" width="200" label="招考方向"/>
        </el-table>
      </div>
      <custom-pagination
        v-show="mainData.Total > 0"
        :pageIndex="query.PageIndex"
        :pageSize="query.PageSize"
        :total="mainData.Total"
        @handleCurrentChange="turnPage"
      ></custom-pagination>
    </div>
  </div>
</template>

<script>
  // import { SchoolSelectExam, NewExamProvinceYear, NewExamProvince } from '@/api/search_university'
  import { getStore } from '@/utils/utils';
  import API from '@/api/config'

  export default {
    data() {
      return {
        mainData:'',
        query: {
          SchoolId: this.$route.query.id,
          ProvinceId: '',
          Year:'',
          PageIndex: 1,
          PageSize: 10
        },
        newExamProvinceList: [],
        newExamYearList: [],
        provinceObj: {}
      }
    },
    computed:{},
    mounted() {
      this.initialize()
    },
    methods: {

      //获取最终数据
      getMainData(){
        API.School.SchoolSelectExam(this.query).then(res=>{
          this.mainData = res.data
          this.mainData.Total = Number(this.mainData.Total)
        })
      },
      //改变省份或年份
      selectParameters(query){
        this.query.PageIndex = 1
        query==='year'?this.getMainData():this.getYearListInProvince()
      },
      //翻页
      turnPage(currentPage){
        this.query.PageIndex = currentPage
        this.getMainData()
      },
      //根据省份获取年份列表
      getYearListInProvince(){
        API.Public.NewExamProvinceYear({
          ProvinceId: this.query.ProvinceId
        }).then(res=>{
          this.newExamYearList = res.data
          this.query.Year = res.data[0]
          //获取最终数据
          this.getMainData()
        })
      },
      //初始化数据列表
      initialize(){
        //获取省份
        let temp = getStore('OrgProvince')
        if (temp) {
          this.provinceObj = JSON.parse(temp)
        }
        this.query.ProvinceId = this.provinceObj.ProvinceId
          
        //获取年份
        this.getYearListInProvince()
        
        // API.Public.NewExamProvince().then(res=>{
        //   this.newExamProvinceList = res.data
        //   this.query.ProvinceId = res.data[0].ID
          
        //   //获取年份
        //   this.getYearListInProvince()
        // })
      }
    }
  }
</script>

<style scoped lang="less">
  .main{
    .head{
      margin: 10px 0 2rem;
      .head-item{
        display: flex;

        .screen-item{
          margin-right: 3rem;
          display: flex;
          align-items: center;
          .title{}
        }
      }
    }
    .content{
      .title{
        margin-bottom: .5rem;
      }
    }
    .emphasize-bold{
      font-weight: 700;
    }
    .emphasize-color-red{
      color: rgba(var(--themecolor),1);
    }
  }

</style>
<style lang="less">
.screen-item .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.screen-item .el-select .el-input__inner:focus,.screen-item .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>